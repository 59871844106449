import React, { useEffect } from 'react';

import { Page } from 'src/app/components';

import EQPoster from 'src/assets/images/equilibre_poster_video_demo_employeur.png';
import EQPlay from 'src/assets/images/equilibre_icon_play_2.png';
import EQVideo from 'src/assets/videos/equilibre_video_demo_employeur_ralentie.mp4';

let listenerVideoPlayAdded = false;
let eventVideoPlaySent = false;
let listenerVideoEndAdded = false;

function addVideoPlayListener() {
  if (typeof document !== `undefined` && !listenerVideoPlayAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('play', () => {
        if (window.gtag && !eventVideoPlaySent) {
          window.gtag('event', 'Video_equilibre_demo_employeur_lp_play');
          eventVideoPlaySent = true;
        }
        listenerVideoPlayAdded = true;
      });
    }
  }
}

function addVideoEndedListener() {
  if (typeof document !== `undefined` && !listenerVideoEndAdded) {
    const video = document.querySelector('video');
    if (video) {
      video.addEventListener('ended', () => {
        if (window.gtag) window.gtag('event', 'Video_equilibre_demo_employeur_lp_ended');
        listenerVideoEndAdded = true;
      });
    }
  }
}

const DemoEmployeur = () => {
  const playVideo = () => {
    const poster = document.getElementById(`poster`);
    poster.style.display = `none`;
    const video = document.getElementById(`video`);
    video.play();
  };

  useEffect(() => {
    addVideoPlayListener();
    addVideoEndedListener();

    // remove useless items from the navbar
    Array.from(document.querySelector('nav').querySelectorAll('li'))
      .slice(0, 3)
      .forEach((item) => item.remove());
  });

  return (
    <Page>
      <Page.Main className="mx-auto max-w-screen-2xl">
        <div
          style={{
            textAlign: 'center',
            padding: '150px 0 50px 0',
          }}
        >
          <div
            className="relative inline-block overflow-hidden"
            onContextMenu={e => e.preventDefault()}
            style={{
              border: '8px solid rgb(0 185 157)',
            }}
          >
            <video
              className="w-full"
              id="video"
              controls
              controlsList="nodownload"
              style={{
                maxWidth: '750px',
              }}
            >
              <source src={EQVideo} type="video/mp4" />
            </video>
            <div id="poster" className="absolute inset-0 flex items-center justify-center">
              <img
                src={EQPoster}
                alt="Equilibre Poster Video"
                className="absolute block"
                style={{
                  width: '100%',
                  height: '100%'
                }}
              />
              <img
                src={EQPlay}
                alt="Equilibre Play Icon"
                className="absolute"
                style={{
                  cursor: 'pointer',
                }}
                onClick={playVideo}
              />
            </div>
          </div>
        </div>
      </Page.Main>
    </Page>
  );
};

export default DemoEmployeur;
